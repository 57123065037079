<template>
    <div class="container-fluid">
        <iframe src="https://app.swonkie.com/login" frameborder="0"></iframe>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    iframe{
        width: 100%;
        min-height: 100vh;
    }
</style>